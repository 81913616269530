<template>
  <div class="v-stack gap-3 h-stretch" v-if="staff">
    <div class="card light h-stretch">
      <div class="content h-stretch">
        <div class="v-stack h-stretch gap-3">
          <label class="text-left">Person</label>
          <input type="text" v-model="staff.user.fullName" disabled />
          <label class="text-left">Role</label>
          <input type="text" v-model="role" disabled />
          <template v-if="checkIfFreelancer()">
            <label class="text-left">Cost</label>
            <input v-model="staff.cost" type="number" />
            <label class="text-left">Gear Cost</label>
            <input v-model="staff.gearCost" type="number" />
          </template>
          <label class="text-left">Description</label>
          <textarea v-model="staff.comment"></textarea>
          <div class="h-stack h-space-between">
            <button @click="remove()" class="edit">Remove</button>
            <button @click="submit()" class="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    staff: {
      type: Object,
      default: null,
    },
    role: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions(["removeStaff", "editStaff"]),
    submit() {
      this.editStaff(this.staff)
        .then(() => {
          this.$emit("dismiss");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove() {
      if (this.staff.roles.length > 1) {
        const index = this.staff.roles.indexOf(this.role);
        if (index !== -1) {
          this.staff.roles.splice(index, 1);
        }
        this.editStaff(this.staff)
          .then(() => {
            this.$emit("dismiss");
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.removeStaff(this.staff._id)
          .then(() => {
            this.$emit("dismiss");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    checkIfFreelancer() {
      const user = this.staff.user;
      if (user.accessLevel == 0) {
        return true;
      } else {
        this.staff.cost = 0;
        this.staff.gearCost = 0;
        return false;
      }
    },
  },
};
</script>